<template>
    <Project 

    logo='kamalayan/KAMALAYAN_LOGO.png'
    title='Kamalayan'
    header='kamalayan/KAM_COVER.png'
    image1='kamalayan/KAM_1.png'
    image2='kamalayan/KAM_2.png'
    color='#2D5F46'

    :who="[

    '<p>It\'s time we give back to those that keep us alive every step of the way. Kamalayan celebrates the significance of our farmers and fisherfolk in sustaining the wellness of a nation, by giving back through local art industries.</p>'

    ]"

    :what="[
    
    '<p>Kamalayan is an art festival to raise awareness on relevant issues happening in the Philippines today. Kamalayan gathers the community in the spirit of bayanihan to unite in promoting the country\'s agricultural sector through local art industries such as visual arts, music, dance, culinary, etc. all for the benefit of our farmers and fisherfolk and their next generations to come.<p>'
        
    ]"

    :why="[
    '<p>We believe that change begins when we influence another to do the same, starting from the basic yet most significant sector being our agriculture. We want to highlight the ways of our culture in promoting regenerative systems that benefit both our people and planet through a festival that celebrates our locals in the spirit of the bayanihan.</p>']"
    
    />

</template>

<script>
import Project from '../components/WWA_Project'
export default {
    components: {
        Project
    }
}

</script>

